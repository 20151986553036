<template>
    <div class="base">
        <br>
        <br>
        <h1 class="base__message text--banner block-xs--xl-top"><slot>{{ message }}</slot></h1>
    </div>
</template>

<script>
export default {
    name: 'BasePage',
    data() {
        return {
            message: '',
        }
    },
    created() {
        this.message = this.$route.meta.message
    },
    methods: {
        toastSuccess() {
            this.$store.dispatch('setToast', {
                type: 'success',
                message: 'This is a SUCCESS Toast.',
            })
        },

        toastError() {
            this.$store.dispatch('setToast', {
                type: 'error',
                message: 'This is an ERROR Toast.',
            })
        },
    },
}
</script>

<style lang="scss">
.base {
    text-align: center;
}
</style>
