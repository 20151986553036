var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base" }, [
    _c("br"),
    _c("br"),
    _c(
      "h1",
      { staticClass: "base__message text--banner block-xs--xl-top" },
      [
        _vm._t("default", function () {
          return [_vm._v(_vm._s(_vm.message))]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }