<template>
    <div>
        <base-page>Dashboard</base-page>
        <router-view />
    </div>
</template>

<script>
import basePage from './base'

export default {
    name: 'DashboardHome',
    components: {
        basePage,
    },
}
</script>

<style lang="scss">
</style>
